<app-container-component tituloPage="INSPEÇÃO DE RECEBIMENTO" subtituloPage="REGISTRAR DE ENTRADA">
  <div class="row d-flex align-items-end justify-content-end">
    <div class="col-12 col-lg-3 mb-3">
      <select name="" id="" class="form-select" (change)="consultaStatus()" [(ngModel)]="status">
        @for (item of listaAvalicao; track $index) {
          <option value="{{item}}">{{item}}</option>
        }
      </select>
    </div>
    <div class="col-12 col-lg-4">
      <div class="input-group mb-3">
        <input type="text" [(ngModel)]="valorDeBusca" class="form-control"
          placeholder="Pesquise por chave de acesso, Nf, Peça"
          (keydown)="consultaPorFiltro($event)">
        <button class="btn btn-sm "
          (click)="consultaPorFiltro()"><i class="fa-solid fa-magnifying-glass"></i></button>
      </div>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table table-hover pointer table-striped align-middle">
      <thead class="table-secondary">
        <tr>
          <td><i class="fa-solid fa-barcode"></i><b> NF</b></td>
          <td><b>Item</b></td>
          <!-- <td>Cliente</td> -->
          <td><b>Peça</b></td>
          <td class="text-center"><b>Avaliação</b></td>
          <td class="text-center"><b>Quantidade</b></td>
          <td class="text-end"><i class="fa-regular fa-calendar"></i><b> Data</b></td>
        </tr>
      </thead>
      <tbody>
        @if(listaInspecaoRecebimento().length > 0) {
        @for (recebimento of listaInspecaoRecebimento(); track $index) {
        <tr title="Clique para visualizar" class="table-row" (click)="editarInspecaoRecebimento(recebimento.id)">
          <td data-label="NF">{{ recebimento.nf }}</td>
          <td data-label="Item">{{ recebimento.itemNf }}</td>
          <td data-label="Peça">{{ recebimento.nomeProduto }}</td>
          <td data-label="Avaliação" class="text-center">
            <span class="badge bg" [ngClass]="{
              'aguardando': recebimento.avaliacao == statusRecebimento.AGUARDANDOCOFERENCIA,
              'aprovado': recebimento.avaliacao == statusRecebimento.APROVADO,
              'retrabalho': recebimento.avaliacao == statusRecebimento.RETRABALHO,
              'qtdDivergente': recebimento.avaliacao == statusRecebimento.QTDIVERGENTE,
              'conferencia': recebimento.avaliacao == statusRecebimento.CONFERENCIA,
              'bloqueado': recebimento.avaliacao == statusRecebimento.BLOQUEADOQUALIDADE,
              'semNf': recebimento.avaliacao == statusRecebimento.SEMNOTA
            }">
              <i *ngIf="pegarIcon(recebimento)" [class]="pegarIcon(recebimento)"></i>
              {{ recebimento.avaliacao }}
            </span>
          </td>
          <td class="text-center" data-label="Quantidade">{{recebimento.qtdFiscal}}</td>
          <td data-label="Data" class="text-end">
            <span class="badge bg">
              <i class="fa-regular fa-calendar"></i> {{recebimento.dataCadastro | date:'dd/MM/yyyy'}}
            </span>
          </td>
        </tr>
        }
        }
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center col-12">
    <app-paginator [quantidadesPorPagina]="quantidadesPorPagina" [paginaAtual]="paginaAtual()"
      [totalPorPagina]="totalPorPagina()" [totalElementos]="totalTamanho()"
      (paginaMudou)="onPageChange($event)"></app-paginator>
  </div>
</app-container-component>